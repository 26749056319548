

import React, { Component } from 'react'
import Overlay from "../Overlay";
import Select from "react-select";
import { showModalNoOutsideClick, getLabel, getReactSelectAriaLabel, getLabelsForMultiSelect, getReactSelectAriaLabelMultiselect } from "../../util/FormatUtil";
import SystemAPI from "../../network/SystemAPI";
import ChildInfoModal from '../modals/ChildInfoModal';
import ParentInfoModal from '../modals/ParentInfoModal';
import SchoolInfoModal from '../modals/SchoolInfoModal';
import Acknowledgement from './Acknowledgement';
import Validator from "../../validation/Validator";
import { Validators } from "../../validation/Validators";
import { sweetalert } from "../../App";
import ExemptionAPI from '../../network/ExemptionAPI';
import SubmissionFileUploadModal from '../modals/SubmissionFileUploadModal';
import { withTranslation } from 'react-i18next';
import PhysicianInfoModal from '../modals/PhysicianInfoModal';


// Define the type for a school object
interface School {
  ID: number;
  SchoolName: string;
  SchoolDistrictID: number;
}


class VaccineExemptions extends Component<any, any> {


  private submissionFileRef: any;



  constructor(props) {
    super(props)
    this.state = {
      states: [],
      countries: [],
      ethnicities: [],
      genders: [],
      races: [],
      ExemptionType: "",
      exemptionOptions: [
        { label: "Medical Exemption", value: 1 },
        { label: "Religious Objection", value: 2 },
        { label: "Personal Objection", value: 3 }
      ],
      ExemptImmunizations: "",
      ObjectionSummary: "",
      childInfo: {},
      parentInfo: {},
      schoolInfo: {},
      childInfoSaved: false,
      parentInfoSaved: false,
      schoolInfoSaved: false,
      acknowledged: false,

    }
    this.submissionFileRef = React.createRef();
  }
  
  componentDidMount(): void { 

    SystemAPI.getAllStates().then(data => {
      this.setState({ states: data })
    })
    SystemAPI.getAllSchoolDistricts().then(data => {
      this.setState({ schoolDistricts: data })
    })
    SystemAPI.getAllSchools().then(data => {
      //@ts-ignore
      this.setState({schools: data.schools})
    })
    SystemAPI.getAllChildCareFacilities().then(data => {
      this.setState({ childCareFacilities: data.facilities })
    })
    SystemAPI.getAllCountries().then(data => {
      this.setState({ countries: data })
    })
    SystemAPI.getAllEthnicities().then(data => {
      this.setState({ ethnicities: data })
    })
    SystemAPI.getAllGenders().then(data => {
      this.setState({ genders: data })
    })
    SystemAPI.getAllRaces().then(data => {
      this.setState({ races: data })
    })
    SystemAPI.getAllImmunizations().then(data => {
      let arrayWithAllSelection = [{ label: "All", value: 0 }].concat(data as { label: string; value: number; }[])
      this.setState({ immunizations: data, immunizationOptions: arrayWithAllSelection })
    })
    SystemAPI.getAllExemptionTypes().then(data => {
      this.setState({ exemptionTypes: data })
    })
    SystemAPI.getAllPersonalExemptionReasons().then(data => {
      this.setState({ exemptionReasons: data })
    })
  }

  validateForm(exemptionInfoCopy) {

    let exemptionFormValidation = {
      ExemptionReason: exemptionInfoCopy.ExemptionReason,
      ObjectionSummary: exemptionInfoCopy.ObjectionSummary,
      ExemptImmunizations: exemptionInfoCopy.ExemptImmunizations.length ? exemptionInfoCopy.ExemptImmunizations : null,
    };

    if (!exemptionInfoCopy.ExemptionType) {
      return { success: false, error: this.props.t("You must select an Exemption Type") }
    }
    if (this.state.ExemptionType === 5 && !this.state.physicianInfoSaved) {
      return { success: false, error: this.props.t("You must complete physician information") }
    }
    if (!this.state.childInfoSaved) {
      return { success: false, error: this.props.t("You must complete child information") }
    }
    if (!this.state.parentInfoSaved) {
      return { success: false, error: this.props.t("You must complete parent information") }
    }
    if (!this.state.schoolInfoSaved) {
      return { success: false, error: this.props.t("You must complete school information") }
    }
    if (!exemptionInfoCopy.acknowledged) {
      return { success: false, error: this.props.t("You must acknowledge all policies listed in this form") }
    }


    let validator;
    if (exemptionInfoCopy.ExemptionType === 5) {
      validator = new Validator<any>()
        .withSimpleValidation("ExemptImmunizations", Validators.requireNonNullValidator("Exempt immunizations"))
    } else if (exemptionInfoCopy.ExemptionType === 2) {
      validator = new Validator<any>()
        .withSimpleValidation("ExemptImmunizations", Validators.requireNonNullValidator("Exempt immunizations filed"))
    } else if (exemptionInfoCopy.ExemptionType === 1) {
      validator = new Validator<any>()
        .withSimpleValidation("ExemptImmunizations", Validators.requireNonNullValidator("Exempt immunizations filed"))
        .withSimpleValidation("ExemptionReason", Validators.requireNonNullValidator("Exemption Reason"))
      if (exemptionInfoCopy.ExemptionReason === 7) {
        validator = new Validator<any>()
          .withSimpleValidation("ObjectionSummary", Validators.requireNonNullValidator("Objection Summary"))
      }
    }



    let validationResponse = validator.validate(exemptionFormValidation);
    if (!validationResponse.success) {
      return { success: false, error: validationResponse.error }
    } else {
      return { success: true }
    }



  }

  onUpload = (state, e) => {
    if (e.target) {
      this.setState({ [state]: e.target.files[0] } as any)
    }
    else {
      this.setState({ [state]: e } as any)
    }
  }

  async submitForm() {

    let exemptImmunizationsArray = this.state.ExemptImmunizations ? this.state.ExemptImmunizations.map(obj => obj.value) : [];
    let contraImmunizationsArray = this.state.ContraImmunizations ? this.state.ContraImmunizations.map(obj => obj.value) : [];


    let exemptionInfoCopy = {
      ExemptionType: this.state.ExemptionType ? JSON.parse(JSON.stringify(this.state.ExemptionType)) : null,
      ContraImmunizations: contraImmunizationsArray ? contraImmunizationsArray : null,
      ExemptionReason: this.state.ExemptionReason ? JSON.parse(JSON.stringify(this.state.ExemptionReason)) : null,
      ObjectionSummary: this.state.ObjectionSummary ? JSON.parse(JSON.stringify(this.state.ObjectionSummary)) : null,
      ExemptImmunizations: exemptImmunizationsArray,
      acknowledged: this.state.acknowledged ? JSON.parse(JSON.stringify(this.state.acknowledged)) : null,
      childInfo: this.state.childInfoSaved ? { ...JSON.parse(JSON.stringify(this.state.childInfo)), ChildRaceID: JSON.parse(JSON.stringify(this.state.childInfo.ChildRaceID)) } : null,
      parentInfo: this.state.parentInfoSaved ? JSON.parse(JSON.stringify(this.state.parentInfo)) : null,
      schoolInfo: this.state.schoolInfoSaved ? JSON.parse(JSON.stringify(this.state.schoolInfo)) : null,
      physicianInfo: this.state.physicianInfoSaved ? JSON.parse(JSON.stringify(this.state.physicianInfo)) : null,
    }


    let res = await this.validateForm(exemptionInfoCopy)
    // @ts-ignore
    if (!res.success) {
      this.setState({ showLoading: false })
      return sweetalert.fire({
        icon: "error",
        title: "",
        // @ts-ignore
        text: this.props.t(res.error),
      });
    } else {

      let ApprovalLetter = this.state.ApprovalLetter ? this.state.ApprovalLetter : null;
      //pass back to parent
      this.setState({ showLoading: true }, async () => {
        let res = await ExemptionAPI.newExemption(exemptionInfoCopy, ApprovalLetter);
        if (res.success) {
          this.setState({ showLoading: false }, () => {
            this.submissionFileRef.current.clearSubmissionFileModalState();
            sweetalert.fire({
              icon: "success",
              title: "",
              // @ts-ignore
              // html:'<p>Your exemption has been submitted. You will receive an email and text shortly.</p><p><strong>NOTE: An exemption must be submitted for each child</strong></p>', 
              text: this.props.t("Your exemption has been submitted. You will receive an email and text shortly. Your exemption will be reviewed within the next 1-3 business days. NOTE: An exemption must be submitted for each child."),
            })
              .then((result) => {
                if (result.isConfirmed) {
                  // window.location.reload()
                  // return to admin per OSDH request
                  window['location'] = '/admin' as unknown as Location;
                }
              });
          })
        } else {
          this.setState({ showLoading: false }, () => {
            return sweetalert.fire({
              icon: "error",
              title: this.props.t("Server Error"),
              // @ts-ignore
              text: this.props.t("Please try again!"),
            });
          })
        }
      })
    }
  }

  render() {


    let translationExemptionTypes = this.state.exemptionTypes && this.state.exemptionTypes.length > 0 ? this.state.exemptionTypes.map(e => { return { label: this.props.t(e.label), value: e.value } }) : [];
    let translationPersonalExemptionReasons = this.state.exemptionReasons && this.state.exemptionReasons.length > 0 ? this.state.exemptionReasons.map(e => { return { label: this.props.t(e.label), value: e.value } }) : [];

    return (
      <div className="container-fluid  min-vh-100 ">
        <Overlay show_loading={this.state.showLoading} />
        <ChildInfoModal
          states={this.state.states}
          countries={this.state.countries}
          race={this.state.races}
          gender={this.state.genders}
          ethnicity={this.state.ethnicities}
          submit={(data) => { this.setState({ childInfo: data, childInfoSaved: true }) }}
        />
        <ParentInfoModal
          states={this.state.states}
          countries={this.state.countries}
          race={this.state.races}
          gender={this.state.genders}
          ethnicity={this.state.ethnicities}
          submit={(data) => { this.setState({ parentInfo: data, parentInfoSaved: true }) }}
        />
        <SchoolInfoModal
          submit={(data) => { this.setState({ schoolInfo: data, schoolInfoSaved: true }) }}
          schools={this.state.schools}
          schoolDistricts={this.state.schoolDistricts}
          childCareFacilities={this.state.childCareFacilities}
          childDOB={this.state.childInfo.ChildDOB}
        />
        <SubmissionFileUploadModal
          ref={this.submissionFileRef}
          clearParentState={() => this.setState({ submissionFiles: [] })}
          handleSubmit={(e) => this.setState({ submissionFiles: e, savedFileUpload: true })}
        />
        <PhysicianInfoModal
          states={this.state.states}
          countries={this.state.countries}
          submit={(data) => { this.setState({ physicianInfo: data, physicianInfoSaved: true }) }}
          upload={(e) => this.onUpload('ApprovalLetter', e)}
        />
        <div className={"row"}>
          <div className=" col-12 col-sm-12  col-md-12 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3 pt-2">
            <main id="main-content" tabIndex={-1} aria-label={this.props.t("Vaccine Records")}>
              <div className="card mt-2">
                <div className="card-header verlag-bold">
                  <h4 className='m-0'>{this.props.t("Vaccine Exemption Form")}</h4>
                </div>
                <div className="card-body">
                  <div className="form-group row col-12 mx-auto mx-sm-0" data-toggle={'tooltip'} data-placement={'top'} title={this.props.t('Type Of Exemption')}>
                    <label id={'TypeOfExemptionLabel'} htmlFor={'TypeOfExemption'} className="col-12 col-sm-4 col-form-label text-left px-2">{this.props.t('Type Of Exemption')}</label>
                    <div className="col-12 col-sm-8 p-0 m-0 text-center text-md-left d-flex justify-content-center align-items-center" id={'Type Of Exemption'}>
                      <Select
                        key={'TypeOfExemption'}
                        id={'TypeOfExemption'}
                        isSearchable={true}
                        aria-label={getReactSelectAriaLabel("Type of Exemption", this.state.ExemptionType ? getLabel(this.state.ExemptionType, this.state.exemptionTypes, this.props.t) : null, this.props.t)}
                        placeholder={<div className="accessibilityText">{this.props.t("Please Select...")}</div>}
                        noOptionsMessage={() => this.props.t("No option")}
                        value={this.state.ExemptionType ? getLabel(this.state.ExemptionType, this.state.exemptionTypes, this.props.t) : null}
                        onChange={(e) => {

                          this.setState((prevState) => ({
                            ExemptionType: e.value,
                            Immunizations: "",
                            ObjectionSummary: "",
                            ContraImmunizations: []
                          }))
                        }}
                        className={'state_select w-100'}
                        options={translationExemptionTypes}
                      />
                    </div>
                  </div>
                  {this.state.ExemptionType === 5 ? (<>
                    <p className='text-danger text-center'>All Medical Exemptions require the approval of a licensed Physician. Further information and direction is contained within the below <i>Physician Information</i> section</p>

                  </>) :
                    this.state.ExemptionType === 2 ?
                      (<>
                      </>) : this.state.ExemptionType === 1 ?
                        (<>
                          <div className="form-group row col-12 mx-auto mx-sm-0" data-toggle={'tooltip'} data-placement={'top'} title={this.props.t('Reason')}>
                            <label id={'ExemptionReason'} htmlFor={'ExemptionReason'} className="col-12 col-sm-4 col-form-label text-center text-md-left">{this.props.t('Reason')}</label>
                            <div className="col-12 col-sm-8 p-0 m-0 text-center text-md-left" id={'ExemptionReason'}>
                              <Select
                                key={'ExemptionReason'}
                                id={'ExemptionReason'}
                                isSearchable={true}
                                aria-label={getReactSelectAriaLabel("Reason", getLabel(this.state.ExemptionReason, this.state.exemptionReasons, this.props.t), this.props.t)}
                                placeholder={<div className="accessibilityText">{this.props.t("Please Select...")}</div>}
                                noOptionsMessage={() => this.props.t("No option")}
                                value={this.state.ExemptionReason ? getLabel(this.state.ExemptionReason, this.state.exemptionReasons, this.props.t) : null}
                                onChange={(e) => {
                                  this.setState((prevState) => ({
                                    ExemptionReason: e.value,
                                  }))
                                }}
                                className={'state_select'}
                                options={translationPersonalExemptionReasons}
                              />
                            </div>
                          </div>
                          {this.state.ExemptionReason === 7 ? <div className="form-group row col-12 mx-auto mx-sm-0" data-toggle={'tooltip'} data-placement={'top'} title={this.props.t("Summary of Objection")}>
                            <label id={"SummaryOfObjection"} htmlFor={"SummaryOfObjection"} className="col-12 col-sm-4 col-form-label text-left px-2">{this.props.t("Summary of Objection")}</label>
                            <div className="col-12 col-sm-8 p-0 m-0 text-center text-md-left d-flex justify-content-center align-items-center" id={"SummaryOfObjection"}>
                              <div className='w-100'>
                                <textarea
                                  className={"form-control mr-3"}
                                  maxLength={600}
                                  autoComplete={"off"}
                                  name={"SummaryOfObjection"}
                                  onChange={(e) => {
                                    this.setState((prevState) => ({
                                      ObjectionSummary: e.target.value
                                    }))
                                  }}
                                  value={this.state.ObjectionSummary}
                                />
                                <div className="row pt-1 pr-3 justify-content-end">
                                  <div className={this.state.ObjectionSummary && this.state.ObjectionSummary.length > 0 ? 'visible' : 'invisible'}>
                                    <div style={{ fontSize: '0.8em' }}>{this.state.ObjectionSummary && this.state.ObjectionSummary.length}/600</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> : <></>}
                        </>) : <></>
                  }
                  <hr />
                  <div className="form-group row col-12 mx-auto mx-sm-0" data-toggle={'tooltip'} data-placement={'top'} title={this.props.t("Select all Immunizations that this exemption applies to")}>
                    <label className="col-12 col-sm-4 col-form-label text-left px-2">{this.props.t("Select all Immunizations that this exemption applies to")}</label>
                    <div className="col-12 col-sm-8 p-0 m-0 text-center text-md-left d-flex justify-content-center align-items-center">
                      <Select
                        isMulti={true}
                        isSearchable={true}
                        placeholder={<div className="accessibilityText">{this.props.t("Please Select...")}</div>}
                        noOptionsMessage={() => this.props.t("No option")}
                        className={"state_select w-100"}
                        value={this.state.ExemptImmunizations ? this.state.ExemptImmunizations : null}
                        options={this.state.immunizationOptions}
                        onChange={(e) => {
                          let indexOfLastElement = e?.length - 1
                          //@ts-ignore
                          if (e && e[indexOfLastElement]?.value === 0) {
                            this.setState({ ExemptImmunizations: this.state.immunizations })
                          } else {
                            this.setState({ ExemptImmunizations: e })
                          }
                        }}
                        aria-label={getReactSelectAriaLabelMultiselect("Please select which Immunizations this exemption applies to:", this.state.ExemptImmunizations, this.props.t)}
                      />
                    </div>
                  </div>
                  {this.state.ExemptionType === 5 &&
                    (
                      <div className="card mb-2 mt-2">
                        <div className="card-header verlag-bold">
                          <div className="row">
                            <div className="col-8 col-sm-10">
                              <h4>{this.props.t("Physician Information")}</h4>
                            </div>
                            <div className="col-4 col-sm-2 d-flex justify-content-center align-items-center">
                              {this.state.physicianInfoSaved ?
                                <>
                                  <button
                                    aria-label={this.props.t("Physician Information Review")}
                                    className={"btn btn-success verlag-bold"}
                                    style={{ float: 'right' }}
                                    onClick={() => showModalNoOutsideClick('Physicianinfo')}>
                                    {this.props.t("Review")}
                                  </button>
                                </>
                                :
                                <button
                                  aria-label={this.props.t("Physician Information Start")}
                                  className={"btn btn-primary verlag-bold"}
                                  style={{ float: 'right' }}
                                  onClick={() => showModalNoOutsideClick('Physicianinfo')}>
                                  {this.props.t("Start")}
                                </button>
                              }

                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  <div className="mb-2">
                    <div className="card mt-2">
                      <div className="card-header verlag-bold">
                        <div className="row">
                          <div className="col-8 col-sm-10">
                            <h4>
                              {this.props.t("Child Information")}
                            </h4>
                          </div>
                          <div className="col-4 col-sm-2 d-flex justify-content-center align-items-center">
                            {this.state.childInfoSaved && this.state.childInfo ? (
                              <>
                                {/* <span className="float-right pl-2">
                                        <BsCheckCircle color={"#1E6111"} size={37} />
                                      </span> */}
                                <button
                                  aria-label={this.props.t('Child Information Review')}
                                  className={"btn btn-success verlag-bold"}
                                  style={{ float: "right" }}
                                  onClick={() =>
                                    showModalNoOutsideClick("childinfo")
                                  }
                                >
                                  {this.props.t("Review")}
                                </button>
                              </>
                            ) : (
                              <button
                                aria-label={this.props.t('Child Information Start')}
                                className={"btn btn-primary verlag-bold"}
                                style={{ float: "right" }}
                                onClick={() =>
                                  showModalNoOutsideClick("childinfo")
                                }
                              >
                                {this.props.t("Start")}
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mb-2">
                    <div className="card mt-2">
                      <div className="card-header verlag-bold">
                        <div className="row">
                          <div className="col-8 col-sm-10">
                            <h4>
                              {this.props.t("Parent/ Guardian Information")}
                            </h4>
                          </div>
                          <div className="col-4 col-sm-2 d-flex justify-content-center align-items-center">
                            {this.state.parentInfoSaved ? (
                              <>
                                {/* <span className="float-right pl-2">
                                        <BsCheckCircle color={"#1E6111"} size={37} />
                                      </span> */}
                                <button
                                  aria-label={this.props.t('Parent/ Guardian Information Review')}
                                  className={"btn btn-success verlag-bold"}
                                  style={{ float: "right" }}
                                  onClick={() =>
                                    showModalNoOutsideClick('parentinfo')
                                  }
                                >
                                  {this.props.t("Review")}
                                </button>
                              </>
                            ) : (
                              <button
                                aria-label={this.props.t('Parent/ Guardian Information Start')}
                                className={"btn btn-primary verlag-bold"}
                                style={{ float: "right" }}
                                onClick={() =>
                                  showModalNoOutsideClick('parentinfo')
                                }
                              >
                                {this.props.t("Start")}
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mb-2">
                    <div className="card mt-2">
                      <div className="card-header verlag-bold">
                        <div className="row">
                          <div className="col-8 col-sm-10">
                            <h4>
                              {this.props.t("School/ Childcare Information")}
                            </h4>
                          </div>
                          <div className="col-4 col-sm-2 d-flex justify-content-center align-items-center">
                            {this.state.schoolInfoSaved ? (
                              <>
                                {/* <span className="float-right pl-2">
                                        <BsCheckCircle color={"#1E6111"} size={37} />
                                      </span> */}
                                <button
                                  aria-label={this.props.t('School/ Childcare Information Review')}
                                  className={"btn btn-success verlag-bold"}
                                  style={{ float: "right" }}
                                  onClick={() =>
                                    showModalNoOutsideClick("schoolinfo")
                                  }
                                >
                                  {this.props.t("Review")}
                                </button>
                              </>
                            ) : (
                              <button
                                aria-label={this.props.t('School/ Childcare Information Start')}
                                className={"btn btn-primary verlag-bold"}
                                style={{ float: "right" }}
                                onClick={() =>
                                  showModalNoOutsideClick("schoolinfo")
                                }
                              >
                                {this.props.t("Start")}
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Acknowledgement
                    onSubmit={() => { this.submitForm() }}
                    onAcknowledge={(bool) => { this.setState({ acknowledged: bool }) }}
                  />
                </div>
              </div>
            </main>

          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(VaccineExemptions);